@import "helpers";
@import "home";

@font-face {
    font-family: 'Vulf_Mono-Black_Italic';
    src:    url('../fonts/Vulf\ Mono/Web/Vulf_Mono-Black_Italic_web.woff') format('woff'),
            url('../fonts/Vulf\ Mono/Web/Vulf_Mono-Black_Italic_web.woff2') format('woff2'),
            url('../fonts/Vulf\ Mono/Web/Vulf_Mono-Black_Italic_web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    font-family: 'Vulf_Mono-Black_Italic' !important;
}